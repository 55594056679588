<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Mobile Menu Bar</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" small @click="editItem(item)"> Add Item </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="record"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>

              <template v-slot:item.status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.status == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>

              <template v-slot:item.id="{ item }">
                <v-btn color="primary" small @click="editItem(item)">
                  Edit
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <edit-dialog
        :editedItem="editedItem"
        :dialog="dialog"
        @close="dialog = false"
        @save="updateItem"
      ></edit-dialog>
      <!-- <edit-dialog
        :editedItem="editedItem"
        :dialog="dialog"
        @close="dialog = false"
        @save="saveItem"
      ></edit-dialog> -->
      <!-- end of editor -->
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
  <!-- snackbar -->
</template>

<script>
import EditDialog from "./_components/editor.vue";

export default {
  components: {
    EditDialog,
  },
  data: () => ({
    dialog: false,
    editedItem: {},
    valid: false,
    search: "",
    loading: false,
    record: [],
    headers: [
      { text: "Key", value: "key" },
      { text: "Label", value: "title" },
      { text: "Staus", value: "status" },
      { text: "Actions", value: "id" },
    ],
  }),
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      this.$store.commit("loader", false);
      this.$store
        .dispatch("get", `/get_mobile_nav`)
        .then((res) => {
          self.record = res.ResponseData;
          this.$store.commit("loader", false);
          this.loading = false;
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    editItem(item) {
      console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.editedItem = {};
      this.dialog = false;
    },
    updateItem(item) {
      console.log(item);
      const self = this;

      const data = item;
      const url = "/update_mobile_nav";
      this.$store.commit("loader", true);
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.closeDialog();
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          self.getData();
        })
        .catch((err) => {
          self.closeDialog();
          self.$refs.snackbar.show(err, "red");
          self.$store.commit("loader", false);
        });
    },

    saveItem(item) {
      console.log(item);
      const self = this;

      const data = item;
      const url = "/update_mobile_nav";
      this.$store.commit("loader", true);
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.closeDialog();
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          self.getData();
        })
        .catch((err) => {
          self.closeDialog();
          self.$refs.snackbar.show(err, "red");
          self.$store.commit("loader", false);
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>