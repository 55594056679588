<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title> Edit Item </v-card-title>
      <v-card-text>
        <v-form ref="editForm" v-model="valid">
          <v-text-field v-model="editedItem.key" label="Key" required></v-text-field>
          <v-text-field v-model="editedItem.title" label="Title" required></v-text-field>
          <v-checkbox
            label="Active"
            required
            v-model="editedItem.status"
            :true-value="'1'"
            :false-value="'0'"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="saveChanges" :disabled="!valid">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    saveChanges() {
      this.$refs.editForm.validate();
      if (this.valid) {
        this.$emit("save", this.editedItem);
      }
    },
  },
};
</script>
